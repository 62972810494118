import { Row, Col, Image } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { experience } from "../../data/experience";

function ProjetPage() {
  return (
    <div className="projet-page">
      <div className="blc">
        <h1>Projets et réalisations</h1>
        {experience.map((item, index) => (
          <div className="projet" key={index}>
            <div className="title">
              <a href={item.link} target="_blank" rel="noreferrer">
                {item.title}
                <LinkOutlined />
              </a>
            </div>
            <div className="entreprise-link">{item.enterprise}</div>
            <div className="desc">{item.description}</div>
            <div className="techno">
              Technologies: <span> {item.technologie} </span>
            </div>
            <div className="visual">
              <Row gutter={24}>
                {item.visual.map((item) => (
                  <Col lg={5} xs={12}>
                    <Image src={`./img/${item}`} preview={true} />
                  </Col>
                ))}
              </Row>
            </div>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
}
export default ProjetPage;
