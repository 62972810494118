import "./styles/style.scss";

function HomePage() {
  return (
    <div className="home-page">
      <div className="blc">
        <h1>Expériences et références</h1>
        <div className="blc-content">
          <h3>Lead Développeur Front-end & Mobile</h3>
          <div className="entreprise">
            <div>
              <a
                href="https://www.wizallmoney.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="./img/wizall-logo.png"
                  alt="img"
                  style={{ width: "40px" }}
                />
              </a>
            </div>
            <div>
              <h4>
                <a
                  href="https://www.wizallmoney.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Wizall Money {""}
                </a>
                - Dakar, Sénégal
              </h4>
              <div className="date"> Depuis janvier 2020 </div>
            </div>
          </div>
          <div className="taches">
            <ul>
              <li>
                Responsable du développement des applications web et mobile de
                Wizall Money dans un environnement React / Flutter (Android &
                IOS)
              </li>
              <li>
                Intégration complète des services de paiement (Transfert,
                Wallet, collecte, Recharge de crédit…)
              </li>
              <li>
                Refonte des applications existantes, et mise en place d'une
                nouvelle architecture applicative.
              </li>
              <li>
                Gérer la maintenance corrective et évolutive des applications
              </li>
              <li>Encadrement et monter en compétences des équipes.</li>
            </ul>
          </div>
        </div>
        <div className="blc-content">
          <h3>Lead Développeur Mobile</h3>
          <div className="entreprise">
            <div>
              <a href="https://concree.com/" target="_blank" rel="noreferrer">
                <img
                  src="./img/concree.logo.png"
                  alt="img"
                  style={{ width: "40px" }}
                />
              </a>
            </div>
            <div>
              <h4>
                <a href="https://concree.com/" target="_blank" rel="noreferrer">
                  Concree{" "}
                </a>
                - Dakar, Sénégal
              </h4>
              <div className="date"> avril 2019 - decembre 2019 </div>
            </div>
          </div>

          <div className="taches">
            <ul>
              <li>Responsable du développement des applications et mobile</li>
              <li>
                Collaboration avec l’équipe back-end pour implémenter des mises
                à jour de fonctionnalités
              </li>
              <li>
                Gérer la maintenance corrective et évolutive des applications
              </li>
              <li>Encadrement et monter en compétences des équipes.</li>
            </ul>
          </div>
        </div>

        <div className="blc-content">
          <h3>Consultant - Développeur web</h3>
          <div className="entreprise">
            <div>
              <a
                href="https://www.pigroup360.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="./img/pi-logo.png"
                  alt="img"
                  style={{ width: "40px" }}
                />
              </a>
            </div>
            <div>
              <h4>
                <a
                  href="https://www.pigroup360.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  People Input{" "}
                </a>
                - Dakar, Sénégal
              </h4>
              <div className="date"> mars 2018 - mars 2019</div>
            </div>
          </div>
          <div className="taches">
            <ul>
              <li>
                Développement from scratch des interfaces à partir de la
                maquette
              </li>
              <li> Implémentation One Signal (Push notifications) </li>
              <li>
                {" "}
                Implémentation Firebase (Real Time Database, Authentication){" "}
              </li>
              <li> Implémentation de la géolocalisation Google Maps </li>
              <li> Déploiement via le store Android et IOS</li>
              <li>
                {" "}
                Développement des sites web dynamiques avec Wordpress from
                scratch{" "}
              </li>
              <li> Maintenance quotidienne des sites web </li>
              <li>
                {" "}
                Intégration et création des templates, modules Wordpress{" "}
              </li>
              <li>
                {" "}
                Rédaction du support technique et documents de formations.
              </li>
            </ul>
          </div>
        </div>

        <div className="blc-content">
          <h3>Chef de projet Front Dev - support Technique</h3>
          <div className="entreprise">
            <div>
              <a
                href="https://www.digitalvirgo.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="./img/dv-logo.png"
                  alt="img"
                  style={{ width: "40px" }}
                />
              </a>
            </div>
            <div>
              <h4>
                <a
                  href="https://www.digitalvirgo.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Digital Virgo{" "}
                </a>
                - Dakar et Casablanca
              </h4>
              <div className="date"> dec 2016 – mars 2018 </div>
            </div>
          </div>
          <div className="taches">
            <ul>
              <li>
                Conduite de projets web & Développement d’outils en interne
              </li>
              <li>
                {" "}
                Responsable de la mise en place des outils d’intégration
                continue{" "}
              </li>
              <li>
                {" "}
                Conception, développement du Dashboard de gestions de contenus
                multimedia (Audio,Video, fichier contrat...) et mise en place
                d'intranet{" "}
              </li>
              <li>
                {" "}
                Conception du design et conduite du projets de l'application
                mobile Ziksen{" "}
              </li>
              <li>
                {" "}
                Développement de la plateforme de Streaming Video « Streaming
                illimité Sénégal, Playweez Sénégal »{" "}
              </li>
              <li>
                {" "}
                Création des Landing pages Ziksen, Sendiine, Streaming illimité
                et Digster{" "}
              </li>
              <li>
                {" "}
                Responsable integration de contenus et maintenance quotidienne
                du site Ziksen, Digster, Sendiine{" "}
              </li>
              <li> Création bannières de tracking pub Google </li>
              <li>
                Création des pages web et mobile en relation avec les équipes du
                Maroc, Sénégal, Aix en provence.
              </li>
            </ul>
          </div>
        </div>

        <div className="blc-content">
          <h3>Développeur Web – Stage </h3>
          <div className="entreprise">
            <div>
              <a
                href="http://www.beut7creative.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="./img/bt7-logo.png"
                  alt="img"
                  style={{ width: "40px" }}
                />
              </a>
            </div>
            <div>
              <h4>
                <a
                  href="http://www.beut7creative.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  beut7creative{" "}
                </a>
                - Dakar, Sénégal
              </h4>
              <div className="date"> mars 2016 - decembre 2016 </div>
            </div>
          </div>
          <div className="taches">
            <ul>
              <li>Conception développement et déploiement des sites web</li>
              <li>
                {" "}
                Développement et maintenance du Dashboard des collettes de
                données sur le terrain.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
