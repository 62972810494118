import React from "react";
import useDarkMode from "use-dark-mode";

const ThemeBtn = () => {
  const darkMode = useDarkMode(true);

  return (
    <div className="toggle-mode">
      <input
        type="checkbox"
        class="checkbox"
        id="checkbox"
        onClick={darkMode.toggle}
      />
      <label for="checkbox" class="label">
        <img src="img/icons8-new_moon.png" alt="img" />
        <img src="img/icons8-sun.png" alt="img" />
        <div class="ball" />
      </label>
    </div>
  );
};
export default ThemeBtn;
