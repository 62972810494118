export const experience = [
  {
    title: "Wizall money Client (Android / IOS)",
    link: "https://apps.apple.com/us/app/wizall-money/id1203292720?l=fr&ls=1",
    enterprise: "https://wizallmoney.com/",
    description:
      "Porte-monnaie électronique permet aux utilisateurs de stocker de l’argent, effectuer des achats, recharger du credit téléphonique en ligne ou physiquement, dans un magasin, suivre l’historique de ses paiements…",
    technologie: "Flutter",
    visual: [
      "wz-client-1.png",
      "wz-client-2.png",
      "wz-client-3.png",
      "wz-client-4.png",
    ],
  },
  {
    title: "Wizall Agent (Android / IOS)",
    link: "https://play.google.com/store/apps/details?id=com.tc.wizall.wizallpartner&gl=US",
    enterprise: "https://wizallmoney.com/",
    description:
      "Wizall Agent est le nouveau service Wizall qui vous permet d’effectuer des opérations de transfert d’argent à faible coût partout au Sénégal. Avec Wizall Agent, envoyez de l’argent à vos proches rapidement et facilement. Il vous permet également d’effectuer des paiements électroniques et bien plus encore. Application réservée aux partenaires.",
    technologie: "Flutter",
    visual: [
      "wz-agent-1.png",
      "wz-agent-2.png",
      "wz-agent-3.png",
      "wz-agent-4.png",
    ],
  },
  {
    title: "Wizall Agent (web)",
    link: "https://agent.wizall.com/",
    enterprise: "https://wizallmoney.com/",
    description:
      "Interface Distributeur qui vous permet d’effectuer des opérations de transfert d’argent à faible coût partout au Sénégal. Avec Wizall Agent, envoyez de l’argent à vos proches rapidement et facilement. Il vous permet également d’effectuer des paiements électroniques et bien plus encore. Application réservée aux partenaires.",
    technologie: "React js",
    visual: ["wz-agent-web-1.png"],
  },
  {
    title: "Adja money Client (Android / IOS)",
    link: "https://play.google.com/store/apps/details?id=com.adjamoney.customer&gl=US",
    enterprise: "https://adjamoney.com/",
    description: "Porte-monnaie électronique",
    technologie: "Flutter",
    visual: [
      "adja-client-1.png",
      "adja-client-2.png",
      "adja-client-3.png",
      "adja-client-4.png",
    ],
  },
  {
    title: "Adja money agent (Android / IOS)",
    link: "",
    enterprise: "https://adjamoney.com/",
    description: "Service de transfert d’argent",
    technologie: "Flutter",
    visual: [],
  },
  // {
  //   title: "Blog Kliner (web)",
  //   link: "https://blog.kliner.com/",
  //   enterprise: "https://kliner.com/",
  //   description: "",
  //   technologie: "Wordpress (From Scratch)",
  //   visual: [],
  // },
  {
    title: "GMS Auto Center (Android)",
    link: "https://play.google.com/store/apps/details?id=com.gmsautocenter.www",
    enterprise: "https://novatrices.tech/",
    description:
      "Une solution numérique de Géolocalisation en temps réel des usagers en situation de détresse médicale. Connecter à notre système d'Assistance médicale, cette application est muni d'un bouton panique digitale qui une fois activée, permet de localiser votre position et d'intervenir avec une équipe de médecin urgentistes dans les plus bref délais.",
    technologie: "Flutter",
    visual: [],
  },
  {
    title: "SOS Abidjan (IOS)",
    link: "https://apps.apple.com/fr/app/sos-abidjan/id1542301049",
    enterprise: "http://www.patientprosn.com/",
    description:
      "L' application vous propose d’avoir à portée de main le carnet d’entretien de votre voiture. Groupez sur une même plateforme toutes les caractéristiques de votre véhicule : informations de la carte grise, assurance, kilométrage, entretien régulier.",
    technologie: "Flutter",
    visual: [],
  },
  {
    title: "AJESUD  (Android)",
    link: "https://play.google.com/store/apps/details?id=io.ajesud.www",
    enterprise: "https://www.concree.com/",
    description:
      "Cette application est la version mobile simplifiée de la plateforme AJESUD. Elle vous permet d’avoir en vue l’essentiel du contenu de votre compte à tout moment. Vous pouvez facilement gérer vos tâches et vos séances de coaching et de formation.",
    technologie: "React Native",
    visual: [],
  },
];
