import "./styles/style.scss";

function FormationPage() {
  return (
    <div className="formation-page">
      <div className="blc">
        <h1>Formations</h1>
        <div>
          <h3>Diplôme d'ingénieur Informatique et Télécom</h3>
          <p>
            2017: ESMT (Ecole Supérieure Multinationale des Télécommunications)
          </p>
        </div>
        <div>
          <h3>
            Diplôme de Technicien Supérieur en Electronique et Informatique
          </h3>
          <p>
            2015: EAMAC (Ecole Africaine de la Météorologie et de l'Aviation
            Civile)
          </p>
        </div>
        <div>
          <h3>Licence professionnelle des TIC(Options: Réseaux et Services)</h3>
          <p>
            2014: ESMT (Ecole Supérieure Multinationale des Télécommunications)
          </p>
        </div>

        <div>
          <h3>Diplôme de Technicien Supérieur en Téléinformatique</h3>
          <p>
            2013: ESMT (Ecole Supérieure Multinationale des Télécommunications)
          </p>
        </div>
      </div>
    </div>
  );
}
export default FormationPage;
