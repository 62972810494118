import { LinkOutlined } from "@ant-design/icons";
import { Row, Col, Image } from "antd";

function OpenSourceProjetPage() {
  return (
    <div className="open-source-projet-page">
      <div className="blc">
        <h1>Projets Open source</h1>
        <div className="projet">
          <div className="title">
            <a
              href="https://github.com/madimakym/react-boilerplate-rtk"
              target="_blank"
              rel="noreferrer"
            >
              React boilerplate rtk
              <LinkOutlined />
            </a>
          </div>
          <div className="description">
            React boilerplate rtk vous permet de réduire la configuration de vos
            projets avec React, Il dispose des meilleures pratiques de
            développement et de bibliothèques populaires. Ainsi ils permettent
            aux développeurs d'économiser une tonne de temps et d'efforts en
            écrivant une énorme quantité de code pour différentes sections.
          </div>
          <div className="techno">
            Technologies:{" "}
            <span>
              {" "}
              React, Redux Toolkit Query, Sass, react-router-dom,
              react-helmet-async, Axios...{" "}
            </span>
          </div>
          <br />
        </div>
        <div className="projet">
          <div className="title">
            <a
              href="https://github.com/madimakym/Wallet-App-Flutter-UI"
              target="_blank"
              rel="noreferrer"
            >
              Wallet App - Flutter UI
              <LinkOutlined />
            </a>
          </div>
          <div className="techno">
            Technologies: <span> Flutter </span>
          </div>
          <div className="visual">
            <Row gutter={24}>
              <Col lg={6} xs={12}>
                <Image src="./img/wallet-app-1.png" preview={false} />
              </Col>
              <Col lg={6} xs={12}>
                <Image src="./img/wallet-app-2.png" preview={false} />
              </Col>
              <Col lg={6} xs={12}>
                <Image src="./img/wallet-app-3.png" preview={false} />
              </Col>
              <Col lg={6} xs={12}>
                <Image src="./img/wallet-app-4.png" preview={false} />
              </Col>
            </Row>
          </div>
          <br />
        </div>

        <br />
        <div className="projet">
          <div className="title">
            <a
              href="https://github.com/madimakym/Travel-App-Flutter-UI"
              target="_blank"
              rel="noreferrer"
            >
              Travel App - Flutter UI
              <LinkOutlined />
            </a>
          </div>
          <div className="techno">
            Technologies: <span> Flutter </span>
          </div>
          <div className="visual">
            <Row gutter={24}>
              <Col lg={6} xs={12}>
                <Image src="./img/travel-app-1.png" preview={false} />
              </Col>
              <Col lg={6} xs={12}>
                <Image src="./img/travel-app-2.png" preview={false} />
              </Col>
              <Col lg={6} xs={12}>
                <Image src="./img/travel-app-3.png" preview={false} />
              </Col>
              <Col lg={6} xs={12}>
                <Image src="./img/travel-app-4.png" preview={false} />
              </Col>
            </Row>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}
export default OpenSourceProjetPage;
