import { NavLink } from "react-router-dom";
import { RiMapPinLine } from "react-icons/ri";
import "./styles/style.scss";

function Header() {
  return (
    <div className="header">
      <div
        className="banner"
        style={{
          backgroundImage: `url('./header-bg.jpg')`,
        }}
      />
      <div className="visual">
        <div className="container">
          <div
            className="avatar"
            style={{
              backgroundImage: `url('./avatar.jpg')`,
            }}
          />
          <div className="infomation-personnelle">
            <div className="name">Maky Madingou</div>
            <div className="post">
              Ingénieur – Développeur Front-end & Mobile
            </div>
            <div className="location">Skype : maky.madi2</div>
            <div className="location">
              <RiMapPinLine /> Dakar, Sénégal
            </div>
          </div>
          <div className="menu">
            <div className="menu-item">
              <NavLink to={"/"}>Présentation</NavLink>
            </div>
            <div className="menu-item">
              <NavLink to={"/realisations"}>Réalisations</NavLink>
            </div>
            <div className="menu-item">
              <NavLink to={"/open-source-projet"}>Projets</NavLink>
            </div>
            <div className="menu-item">
              <NavLink to={"/formations"}>Formations</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
