import { Row, Col } from "antd";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import ThemeBtn from "./components/ThemeBtn";
import FormationPage from "./features/formations";
import HomePage from "./features/home";
import OpenSourceProjetPage from "./features/open-source-projet";
import ProjetPage from "./features/projets";
import { useTheme } from "./utils/useTheme";

function App() {
  const theme = useTheme();
  return (
    <div className={theme}>
      <ThemeBtn />
      <BrowserRouter>
        <Header />
        <div className="container">
          <Row className="wrapper">
            <Col lg={8}>
              <Sidebar />
            </Col>
            <Col lg={16} xs={24}>
              <div className="content">
                <Routes>
                  <Route>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/formations" element={<FormationPage />} />
                    <Route path="/realisations" element={<ProjetPage />} />
                    <Route
                      path="/open-source-projet"
                      element={<OpenSourceProjetPage />}
                    />
                  </Route>
                </Routes>
              </div>
            </Col>
          </Row>
          <br />
          <br />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
