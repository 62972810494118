import "./styles/style.scss";

function Sidebar() {
  return (
    <div className="sidebar">
      <div className="blc">
        <h1>Présentation</h1>
        <p>
          Je suis développeur Front-end et designer d’interfaces basé à Dakar.
          Avec plus de 6 années d’experience dans le domaine du web et
          d’applications mobile. Mon travail est axé sur la conception de A à Z
          des produits interactifs fonctionnels fiables et innovants.
        </p>
      </div>

      <div className="blc">
        <h1>Compétences</h1>
        <h3>Développement mobile:</h3>
        <ul>
          <li>
            Techno: <span>Flutter</span>
          </li>
          <li>
            State management: <span>Flutter_bloc, Cubit</span>
          </li>
        </ul>

        <h3>Développement Front-end:</h3>
        <ul>
          <li>
            Techno: <span>React, Bootstrap, Ant-design</span>
          </li>
          <li>
            State management: <span>Redux toolkit, rtk query</span>
          </li>
          <li>
            Design d'interfaces: <span>Adobe XD, Sketch</span>
          </li>
        </ul>

        <h3>Développement Backend-end:</h3>
        <ul>
          <li>
            Techno: <span>Nest js, Wordpress (Rest API)</span>
          </li>
          <li>
            Base de donnée: <span>MongoDB, Mysql</span>
          </li>
          <li>Autres: <span>Git, Gitflow, Firebase</span></li>
        </ul>
      </div>

      <div className="blc">
        <h1>Langues</h1>
        <ul>
          <li>
            Français : <span>Courant</span>
          </li>
          <li>
            Anglais : <span>Technique</span>
          </li>
        </ul>
      </div>

      <div className="blc">
        <h1>Centres d'interets</h1>
        <ul>
          <li>Photographe amateur</li>
          <li>Animes, Mangas, Geek</li>
        </ul>
      </div>
    </div>
  );
}
export default Sidebar;
